/**
 * Contains all weather widget components.
 */

import domContentLoaded from 'utils/domContentLoaded';
import reactComponentManager from 'utils/reactComponentManager';
import IframeConditions from 'components/weather/iframeConditions';
import IframeLocation from 'components/weather/iframeLocation';
import IframeRadar from 'components/weather/iframeRadar';

import 'scss/weather-conditions/index.scss';

domContentLoaded(() => {
  reactComponentManager({
    IframeConditions,
    IframeLocation,
    IframeRadar,
  });
});

if (module.hot) {
  module.hot.accept();

  reactComponentManager({
    IframeConditions,
    IframeLocation,
    IframeRadar,
  });
}
