/* eslint-disable import/prefer-default-export */
export const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;

/**
 * Return an element from parsed XML
 *
 * @param {Object} xml - parsed xml of object from react-xml-parser;
 * @param {String} tagName
 * @param {String} attr
 */
export function getXmlAttr(xml, tagName, attr) {
  if (tagName && xml.getElementsByTagName(tagName)) {
    if (attr) {
      return xml.getElementsByTagName(tagName)[0].attributes[attr];
    }

    return xml.getElementsByTagName(tagName)[0].children;
  }

  if (! tagName) {
    if (attr) {
      return xml.children[0].attributes[attr];
    }
  }

  return null;
}

export const iconMap = {
  0: null,
  1: null,
  2: null,
  3: 84,
  4: 84,
  5: 79,
  6: 79,
  7: 79,
  8: 90,
  9: 76,
  10: 89,
  11: 76,
  12: 87,
  13: 77,
  14: 74,
  15: 81,
  16: 74,
  17: 84,
  18: 88,
  19: 75,
  20: 70,
  21: 72,
  22: 75,
  23: 78,
  24: 78,
  25: 78,
  26: 67,
  27: 99,
  28: 69,
  29: 103,
  30: 66,
  31: 102,
  32: 65,
  33: 98,
  34: 66,
  35: 84,
  36: 65,
  37: 95,
  38: 95,
  39: 76,
  40: 82,
  41: 74,
  42: 83,
  43: 80,
  44: 86,
  45: 105,
  46: 109,
  47: 101,
};

/* eslint-enable import/prefer-default-export */
