import React from 'react';
import { render } from 'react-dom';

/**
 * Converts Values within Data Object parsed from data attribute. e.g boolean strings into boolean
 *
 * @param {Object} dataset HTML string from rendered JSON output.
 * @returns {Object}
 */
export const convertDataset = (dataset) => {
  const data = dataset;
  // Attempt to decode JSON attributes.
  Object.keys(data).forEach((key) => {
    const value = data[key];

    // Convert 'true/false' to booleans.
    if ('true' === value) {
      data[key] = true;
      return value;
    }

    if ('false' === value) {
      data[key] = false;
      return value;
    }

    if (
      'string' === typeof value
      && (
        '{' === value[0]
        || '[' === value[0]
      )
    ) {
      try {
        const decoded = JSON.parse(value);

        if ('object' === typeof decoded) {
          data[key] = decoded;
        }
      } catch (e) {
        return value;
      }
    }

    return value;
  });

  return data;
};

/**
 * Render a component on a target.
 *
 * Allows the component manager to be extensible to render components on the fly.
 *
 * @param {Object} Component Component object.
 * @param {Object} target Target object.
 */
export const renderComponent = (Component, target) => {
  let dataset = { ...target.dataset };

  // Attempt to decode JSON attributes.
  dataset = convertDataset(dataset);

  // Inner HTML as a prop.
  dataset.innerHTML = target.innerHTML;
  dataset.elementTarget = target;

  if ('local' === nbc.env) {
    console.log('Render react component', Component, dataset, target); // eslint-disable-line no-console
  }

  render(
    <Component
      {...dataset}
    />,
    target
  );
};

/**
 * React Component Manager
 *
 * Initialize React components on the fly and as many times as requested
 * on the page. Pass the dataset from the rendering element to the component
 * as properties.
 *
 * @param  {Object} components Object of component names and methods to render the components.
 */
export default function reactComponentManager(components, scope) {
  const configureComponent = (componentName, Component) => {
    const thisScope = ('undefined' === typeof scope) ? '' : scope;
    const targets = Array
      .from(document.querySelectorAll(`${thisScope}[data-react-component="${
        componentName
      }"]`))
      .filter((tar) => {
        const target = tar;

        // Prevent re-rendering component.
        if (target.dataset.componentInitialized) {
          return false;
        }

        target.dataset.componentInitialized = true;
        return true;
      });

    Array.from(targets).forEach((target) => renderComponent(Component, target));
  };

  Object.keys(components).forEach((component) => {
    configureComponent(component, components[component]);
  });
}
