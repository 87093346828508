import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withWeatherData from './withWeatherData';

class IframeLocation extends PureComponent {
  static defaultProps = {
    marketName: '',
    data: {
      cityName: '',
      stateAbbr: '',
    },
  };

  static propTypes = {
    marketName: PropTypes.string,
    data: PropTypes.shape({
      cityName: PropTypes.string,
      stateAbbr: PropTypes.string,
    }),
  };

  render() {
    const {
      props: {
        data,
        marketName,
      },
    } = this;

    const locationName = (nbc.zipCode !== data.zip) && (!! data.cityName) ?
      `${data.cityName}, ${data.stateAbbr}` : `${marketName}`;

    return (
      <div className="widget_location">{locationName}</div>
    );
  }
}

export default withWeatherData('current')(IframeLocation);
