import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withWeatherData from './withWeatherData';

class IframeConditions extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      iconCode: PropTypes.string,
      temp: PropTypes.string,
      conditions: PropTypes.string,
      feelsLike: PropTypes.string,
      humidity: PropTypes.string,
      wind: PropTypes.string,
      windDir: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      props: {
        data,
      },
    } = this;

    const {
      i18n: {
        __,
      },
    } = wp;

    return (
      <div className="weather-widget conditions">
        <div className="current">
          <div className="temp">
            {data.temp}&deg;
            <div
              className={
                `widget-weather-icon weather-icon--${data.iconCode}`
              }
            />
          </div>
          <div>
            {data.conditions}
          </div>
          <div>
            {data.precip}% {__('Precip', 'nbc')}
          </div>
        </div>
        <div className="tonight-tomorrow">
          <div className="tonight">
            {__('Tonight', 'nbc')}
            <div className="temp">
              {data.tonightTemp}&deg;
              <div
                className={
                  `widget-weather-icon weather-icon--${data.nightIconCode}` // eslint-disable-line max-len
                }
              />
            </div>
          </div>
          <div className="tomorrow">
            {__('Tomorrow', 'nbc')}
            <div className="temp">
              {data.tomorrowHiTemp}&deg;
              <div
                className={
                  `widget-weather-icon weather-icon--${data.tomorrowIconCode}` // eslint-disable-line max-len
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWeatherData('current')(IframeConditions);
