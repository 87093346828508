import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lscache from 'lscache';

import {
  zipCodePattern,
} from './utils';

const IframeRadar = (props) => {
  IframeRadar.defaultProps = {
    settings: {
      map: {
        memberid: '',
        mapid: '',
        templateid: '',
        zoom: '',
        menu_items: '',
      },
      latitude: '',
      longitude: '',
      lang: 'en',
    },
  };

  IframeRadar.propTypes = {
    settings: PropTypes.shape({
      map: PropTypes.shape({
        memberid: PropTypes.string,
        mapid: PropTypes.string,
        templateid: PropTypes.string,
        zoom: PropTypes.string,
        menu_items: PropTypes.string,
      }),
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      lang: PropTypes.string,
    }),
  };

  // Setup state for coordinates.
  const [coordinates, setCoordinates] = useState({
    latitude: props.settings.latitude,
    longitude: props.settings.longitude,
  });

  /**
   * Process the JSON.
   *
   * @param {Object} json
   * @returns {Object}
   */
  const processJSON = (json) => {
    const [{
      city_info: {
        latitude: lats,
        longitude: longs,
      },
    }] = json;

    if ((! lats || 0 === lats.length) || (! longs || 0 === longs.length)) {
      return { lat: '', lng: '' };
    }

    return {
      lat: lats,
      lng: longs,
    };
  };

  /**
   * Fetch data from weather API.
   *
   * @param {String} inputValue Search query.
   * @returns {Promise<Array | never>}
   */
  const locale = 'telemundo' === nbc.brand ? '&language=es-US' : '&language=en-US'; // eslint-disable-line max-len
  const getResults = (inputValue) => fetch(`https://api.weather.com/v3/location/search?query=${inputValue}${locale}&format=json&countryCode=US&apiKey=c8abfe32a8c14073abfe32a8c120731d`)
    .then((response) => {
      if (! response.ok) {
        throw response;
      }
      return response;
    })
    .then((response) => response.json()
      .then((json) => processJSON(json)));

  /**
   * Set coordinates if they are present.
   *
   * @param {Object} coords The coordinates.
   */
  const handleSetCoordinates = (coords) => {
    if (coords.lat && coords.lng) {
      setCoordinates({
        latitude: coords.lat,
        longitude: coords.lng,
      });
    }
  };

  useEffect(() => {
    if ('undefined' !== typeof window.wxWidgets) {
      window.wxWidgets.parse();
    }

    // If local storage cookie is set, then use lat and long from there.. otherwise, use the default.
    const {
      nbc: {
        param_zipcode: paramZipcode = '',
      } = {},
    } = window || {};

    const zipCode = paramZipcode || localStorage.getItem('zipCode');

    let {
      lat = '',
      lng = '',
    } = JSON.parse(localStorage.getItem('nbc_weather_coords') || '{}');

    if (paramZipcode) {
      lat = '';
      lng = '';
    }

    // If the zip code is valid
    if (zipCodePattern.test(zipCode) || (lat && lng)) {
      if (lat && lng) {
        handleSetCoordinates({ lat, lng });
        return;
      }

      // Set the Local Storage key
      const key = `wx_${zipCode}_current`;

      // Get the Local Storage key
      const xmlText = lscache.get(key);

      let coords = {};
      // If the data exists in local storage, use it.
      if (xmlText) {
        coords = processJSON(xmlText);

        handleSetCoordinates(coords);
      }
      if (! coordinates.latitude) {
        getResults(zipCode)
          .then((res) => {
            handleSetCoordinates(res);
          });
      }
    }
  }, []);

  return (
    <div className="weather-module-map">
      <wx-widget
        type="map"
        memberId={props.settings.map.memberid}
        mapId={props.settings.map.mapid}
        templateId={props.settings.map.templateid}
        theme="light-gray"
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoomLevel={props.settings.map.zoom}
        menuItems={props.settings.map.menu_items}
        standalone="true"
        fullscreen="false"
        language={props.settings.lang}
        header=""
      />
    </div>
  );
};

export default IframeRadar;
