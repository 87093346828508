import React, { Fragment, useEffect, useState } from 'react';
import querystring from 'querystring';

import {
  iconMap,
  zipCodePattern,
} from '../utils';

const withWeatherData = (dataType) => (WrappedComponent) => {
  // @todo supply fallback zip code via config
  const {
    nbc: {
      zipCode = '10003',
      param_zipcode: paramZipcode = '',
    } = {},
  } = window;

  const DataProvider = (props) => {
    const [data, updateData] = useState({
      current: {},
      hourly: [],
      tenDay: [],
      alerts: [],
      header: {
        current: {},
        alerts: [],
      },
    });

    const [componentError, setComponentError] = useState({
      isError: false,
      errorMsg: '',
    });

    /**
     * Consolidate duplicate icon codes:
     * Many of the icon codes correspond to the same icon
     *
     * @param {string} iconCode - the icon code from the API.
     */
    const mapIconCodes = (iconCode) => {
      switch (iconCode) {
        case '26':
        case '28':
          return '67'; // cloudy
        case '32':
        case '36':
          return '65'; // sunny
        case '19':
        case '22':
          return '75'; // smoke
        case '43':
          return '80'; // blizzard
        case '40':
          return '82'; // heavy rain
        case '92':
          return '91';
        case '101':
        case '116':
          return '100';
        case '33':
          return '98'; // mostly clear
        case '27':
          return '99'; // mostly cloudy
        case '45':
          return '105'; // scattered showers
        case '42':
          return '83'; // heavy snow
        case '5':
        case '6':
        case '7':
          return '79'; // rain/snow/ice
        case '114':
          return '113';
        case '115':
          return '97';
        default:
          return iconMap[iconCode];
      }
    };

    /**
     * Process the json data to pull out values and store them in state.
     *
     * @param string text
     */
    const processJSON = (json) => {
      if ('hourly' === dataType) {
        const hourlyData = json.hourly_forecast;
        const hourlyItems = hourlyData.map((item, index) => {
          const {
            dayOfWk: dayOfWeek = '',
            time: {
              local_date: time = '',
            } = {},
            tempF: temp = '',
            iconCode = '',
            precip = '',
          } = item;

          return {
            hourNum: index,
            dayOfWeek,
            time,
            temp,
            iconCode: mapIconCodes(iconCode),
            precip,
          };
        });

        updateData({
          ...data,
          hourly: hourlyItems,
        });
      }

      if ('tenDay' === dataType) {
        const tenDayData = json.daily_forecast;

        const tenDayItems = tenDayData.slice(0, 10).map((item) => {
          const {
            dayOfWk: day = '',
            iconCode = '',
            sky = '',
            hiTempF: hiTemp = '',
            loTempF: loTemp = '',
            precip = '',
            daypart: {
              day: {
                narrative: dayNarrative = '',
              } = {},
              night: {
                narrative: nightNarrative = '',
              } = {},
            } = {},
          } = item;

          return {
            day,
            iconCode: mapIconCodes(iconCode),
            sky,
            hiTemp,
            loTemp,
            // feelsLike: attributes., // @todo confirm what the second temp figure is in mocks. feels like does not exist on daily data.
            precip,
            conditions: dayNarrative || nightNarrative,
          };
        });

        updateData({
          ...data,
          tenDay: tenDayItems,
        });
      }

      if ('current' === dataType) {
        const location = json.city_info;
        const currentData = json.current_observation;
        const {
          daily_forecast: {
            0: {
              // tonight.
              daypart: {
                night: {
                  iconCode: tonightIconCode = '',
                  temperature: tonightTemp = '',
                } = {},
              } = {},
            } = {},
            1: {
              // next day.
              hiTempF: tomorrowHiTemp = '',
              iconCode: nextDayIconCode = '',
            } = {},
          } = {},
        } = json;

        const currentItems = {
          iconCode: mapIconCodes(currentData.iconCode),
          nightIconCode: mapIconCodes(tonightIconCode),
          temp: currentData.tempF,
          tonightTemp,
          hiTemp: currentData.hiTempF,
          loTemp: currentData.loTempF,
          conditions: currentData.phraseDay,
          feelsLike: currentData.feelsLikeF,
          precip: currentData.precip,
          humidity: currentData.humidity,
          wind: currentData.windSpeed,
          tomorrowHiTemp,
          tomorrowIconCode: mapIconCodes(nextDayIconCode),
          cityName: location.city,
          stateAbbr: location.state,
          zip: location.prefZipCode,
          lat: location.latitude,
          long: location.longitude,
        };

        updateData({
          ...data,
          current: currentItems,
        });
      }

      if ('header' === dataType) {
        const currentData = json.current_observation;
        const currentItems = {
          iconCode: mapIconCodes(currentData.iconCode),
          temp: currentData.tempF,
          conditions: currentData.phraseDay,
        };

        wp.apiFetch({
          path: '/nbc/v1/weather-alerts',
        })
          .then((alerts) => {
            updateData({
              ...data,
              header: {
                current: currentItems,
                alerts,
              },
            });
          });
      }
    };

    /**
     * Request json data from api, store it in localstorage, then process the data into state.
     * @param {string} zip     the zip code.
     * @param {string} key     the Local Storage key.
     * @param {object} options options object.
     */
    const getData = (zip, lsKey, options) => {
      const path = 'telemundo' === nbc.brand ? 'el-tiempo' : 'weather';
      const {
        lat = '',
        lng = '',
      } = options;

      const params = {};

      if (lat && lng) {
        params.geocode = `${lat},${lng}`;
      } else {
        params.zipCode = zip;
      }

      const url = `/${path}/latest.json/?${querystring.stringify(params)}`;

      if (
        nbc.preloadedResources
        && 'undefined' !== typeof nbc.preloadedResources[url]
      ) {
        processJSON(nbc.preloadedResources[url]);
      } else {
        fetch(url)
          .then((response) => {
            if (! response.ok) {
              throw response;
            }
            return response;
          })
          .then((response) => response.json()
            .then((json) => {
              processJSON(json);
            }))
          .catch((error) => {
            setComponentError({
              isError: true,
              errorMsg: `API error: ${error.message}`,
            });
          });
      }
    };

    /**
     * Process json data from the API into state.
     */
    useEffect(() => {
      if (dataType) {
        const userZip = localStorage.getItem('zipCode');
        const zip = paramZipcode || userZip || zipCode;

        let {
          lat = '',
          lng = '',
        } = JSON.parse(localStorage.getItem('nbc_weather_coords') || '{}');

        if (paramZipcode) {
          // force zipcode parameter.
          lat = '';
          lng = '';
        }

        // If the zip code is valid
        if (zipCodePattern.test(zip) || (lat && lng)) {
          // Set the Local Storage key
          const prefix = (zip && 'null' !== zip) ? zip : [lat, lng].join(',');
          const key = `wx_${prefix}_${dataType}`;
          getData(zip, key, { lat, lng });
        }
      } else {
        setComponentError({
          isError: true,
          errorMsg: 'No `dataType` supplied to withWeatherData component.',
        });
      }
    }, []);

    // @todo styles for error messages.
    return (
      <Fragment>
        {componentError.isError && (
          <div>{componentError.errorMsg}</div>
        )}

        {(! componentError.isError && dataType) && (
          <WrappedComponent
            {...props}
            data={data[dataType]}
          />
        )}
      </Fragment>
    );
  };

  return DataProvider;
};

export default withWeatherData;
